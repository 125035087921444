<template>
  <b-table :fields="table.fields" :items="tableItems" :class="tableClasses" striped small fixed caption-top
           thead-class="table-header text-center text-white" tbody-tr-class="table-row">
    <template #cell(username)="data">
      <b-row class="px-3">
        <b-col>
          <span>{{ data.item.username }}</span>
        </b-col>
        <b-col cols="auto" class="ml-auto pr-1">
          <b-icon-pencil font-scale="1" class="clickable" @click="edit(data.item)"/>
        </b-col>
      </b-row>
    </template>
    <template #cell()="data">
      <b-form-checkbox :checked="inputCheck(data.item.roles, data.field.key)" @change="inputChange(data.item.roles, data.field.key, data.item.username)"/>
    </template>
    <template #custom-foot>
      <b-tr class="table-footer">
        <b-th v-for="(index) in table.fields" :key="index"/>
      </b-tr>
    </template>
  </b-table>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import {mapGetters} from "vuex";
import {toastDanger, toastSuccess} from "@/modules/status";

export default {
  name: "UserTable",
  props: ['tableItems'],
  data() {
    return {
      table: {
        fields: [
          {key: 'username', label: 'Nutzername'}
        ]
      }
    }
  },
  methods: {
    edit(data) {
      this.$emit('edit-user', data)
    },
    refresh() {
      this.$emit('refresh')
    },
    inputCheck(roles, key) {
      return roles.indexOf(key) > -1;
    },
    inputChange(roles, key, username) {
      if(roles.indexOf(key) > -1) {
        _.pull(roles, key)
      }
      else {
        roles.push(key)
      }
      this.updateRoles(username, roles)
    },
    async getRoles() {
      await axios.get(process.env.VUE_APP_API_USERS_LDAP_ROLES)
          .then((response) => {
              response.data.forEach(element => {
                  this.table.fields.push({key: element.name, label: element.displayname, tdClass: 'text-center'})
              })
          })
    },
    async updateRoles(username, roles) {
      let data = {
        username: username,
        roles: roles
      }

      await axios.put(process.env.VUE_APP_API_USERS_LDAP_ROLES, data)
          .then(() => {
              toastSuccess(this, 'Der Eintrag wurde erfolgreich aktualisiert.')
              this.refresh()
          })
          .catch(() => {
              toastDanger(this, 'Der Eintrag konnte nicht aktualisiert werden.')
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    tableClasses() {
      if(this.userThemeId === 2) {
        return []
      }
      return ['text-white']
    }
  },
  mounted() {
    this.getRoles()
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/Tables';
</style>
